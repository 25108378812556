import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import ReactSlider from "react-slider"; //https://github.com/zillow/react-slider
import "./timelineSlider.css";
export const TimelineSlider = ({ id, testId, label, value = 0, onChange, step = 1, min = 0, max = 100, trackClassName, thumbClassName, invert = false, customMarks, disabled, hide, renderMark = (props) => {
    return _jsx("span", Object.assign({}, props));
}, }) => {
    // console.log("value: ", value);
    // const isPrime = (numValue: number): boolean => {
    //   for (let i = 2, s = Math.sqrt(numValue); i <= s; i++) {
    //     if (numValue % i === 0) return false;
    //   }
    //   return numValue > 1;
    // };
    // const areFactorsOf = (numValue: number): number[] => {
    //   let factors = [];
    //   for (let i = 1; i <= numValue; i++) {
    //     // check if number is a factor
    //     if (numValue % i === 0) {
    //       factors.push(i);
    //     }
    //   }
    //   return factors;
    // };
    // const range = ({ from = 0, to = 100, interval = 1, length = 8 }): number[] =>
    //   Array.from({ length }, (_, i) => from + i * interval);
    // console.log("now: ", new Date().toString(), new Date().toUTCString());
    // const getIdealFactor = (vals: number[]): number | undefined =>
    //   vals.find((x) => x > 5 && x < 9);
    // const getMarksLabel = (minVal: number, maxVal: number): number[] => {
    //   let minMaxRange = maxVal + 1 - minVal;
    //   if (isPrime(minMaxRange)) {
    //     minMaxRange--;
    //   }
    //   let idealLength;
    //   while (idealLength === undefined) {
    //     idealLength = getIdealFactor(areFactorsOf(minMaxRange));
    //     if (idealLength === undefined) {
    //       minMaxRange--;
    //     }
    //   }
    // console.log("idealLength: ", idealLength);
    //   let markLabels = range({
    //     from: min,
    //     to: max,
    //     interval: minMaxRange / idealLength,
    //     length: idealLength,
    //   });
    //   // console.log("min, max, interval, arrLength: ", min, max, minMaxRange / idealLength, idealLength);
    //   return markLabels.filter((i) => i !== 0);
    // };
    // const userDefinedMarks = customMarks ?? getMarksLabel(min, max);
    const [currentVal, setCurrentVal] = useState(value);
    const handleOnChange = (val) => {
        setCurrentVal(val);
        onChange(val);
    };
    useEffect(() => {
        setCurrentVal(value);
    }, [value]);
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "eco-timeline-slider", className: "relative w-full", children: [_jsx(ReactSlider, { className: `w-full ${hide === true ? "hide" : ""}`, thumbClassName: thumbClassName !== null && thumbClassName !== void 0 ? thumbClassName : "eco-timeline-slider-thumb", thumbActiveClassName: "eco-timeline-slider-thumb-active", trackClassName: trackClassName !== null && trackClassName !== void 0 ? trackClassName : (typeof value === "number"
                    ? "eco-timeline-slider-track"
                    : "eco-timeline-slider-2thumb-track"), marks: customMarks, renderMark: renderMark, markClassName: "eco-timeline-slider-mark", value: currentVal, onChange: (val) => handleOnChange(val), min: min, max: max, step: step, ariaLabelledby: id, invert: invert, disabled: disabled }), _jsx("span", { className: "sr-only", children: label })] }));
};
