import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Legend = ({ id, testId, label, unit, colorTable, }) => {
    return (_jsxs("div", { "data-eco-component": "legend", id: id, "data-test-id": testId, className: "bg-container-light dark:bg-dark-container-light flex flex-row space-x-4", children: [_jsx("div", { className: "text-tertiary dark:text-dark-tertiary text-sm font-normal", children: label }), _jsxs("div", { className: "flex flex-row", children: [colorTable.map((i, index) => {
                        return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { style: {
                                        backgroundColor: i.color,
                                        width: "1rem",
                                        height: "1rem",
                                    }, children: "\u00A0" }), _jsx("div", { style: { width: "1rem", height: "1rem" }, className: "text-tertiary dark:text-dark-tertiary text-xs", children: i.value })] }, `${id}-${index}`));
                    }), _jsx("div", { className: "text-tertiary dark:text-dark-tertiary ml-2 text-sm font-normal", children: unit })] })] }));
};
