var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter } from "next/router";
import { Breadcrumbs } from "../breadcrumbs";
import Pill from "../pill/Pill";
import { Avatar } from "../avatar";
import { Heading } from "../typography/heading";
import { IconV2 } from "../icon_v2";
import { Button } from "../button";
import { Splitbutton } from "../form/splitbutton/Splitbutton";
import { SplitbuttonOption, } from "../form/splitbutton/SplitbuttonOption";
export const PageShell = (_a) => {
    var { id, testId, useBreadcrumbs = true, showBreadcrumbsHome = true, customBreadcrumbs, showTitle = true, customPageTitle, actionOptions, avatarName, subtextBeforeTitle, subtextAfterTitle, iconTextOptions, pillMessage, useSplitbutton = false, splitButtonOptions } = _a, props = __rest(_a, ["id", "testId", "useBreadcrumbs", "showBreadcrumbsHome", "customBreadcrumbs", "showTitle", "customPageTitle", "actionOptions", "avatarName", "subtextBeforeTitle", "subtextAfterTitle", "iconTextOptions", "pillMessage", "useSplitbutton", "splitButtonOptions"]);
    const router = useRouter();
    if (router === undefined) {
        return (_jsx(_Fragment, { children: "Please use this component within a NextJS application, it requires useRouter." }));
    }
    /**
     * Using the current path, strip off the forward-slash, remove underscores,
     * hyphens, or camelCasing, and make readable then titlecase the string
     *
     * @returns String
     */
    const pathNameToTitle = () => {
        var _a;
        const currentPath = router !== undefined
            ? // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                (_a = router.pathname.split("/")[1]) !== null && _a !== void 0 ? _a : "home"
            : undefined;
        const cleanedStr = currentPath !== undefined
            ? currentPath
                .replace(/[_-]/, " ")
                .replace(/([0-9A-Z])/g, " $&")
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                .join(" ")
            : null;
        return cleanedStr;
    };
    const title = customPageTitle !== null && customPageTitle !== void 0 ? customPageTitle : pathNameToTitle();
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "page-shell", className: "flex-grow", children: [useBreadcrumbs && router !== undefined && (_jsx("div", { id: "page-header", className: "flex flex-row items-end justify-between px-4 pt-3 sm:px-6 md:px-8", children: _jsx(Breadcrumbs, { pageLabel: customPageTitle, showRootLabel: showBreadcrumbsHome, customCrumbs: customBreadcrumbs }) })), (avatarName !== undefined || showTitle || actionOptions) && (_jsxs("div", { className: "border-dark dark:border-dark-dark flex flex-col border-b px-4 py-3 sm:px-6 md:px-8 lg:flex-row lg:justify-between lg:gap-8", children: [_jsxs("div", { className: "flex flex-row gap-4", children: [avatarName !== undefined && (_jsx("div", { className: "hidden flex-col justify-center lg:flex", children: _jsx(Avatar, { id: "pageShell-avatar", name: avatarName, size: "lg", color: "slate" }) })), (showTitle || actionOptions) && (_jsxs("div", { className: "space-y-1", children: [subtextBeforeTitle !== undefined && (_jsx("p", { className: "text-secondary dark:text-dark-secondary text-sm", children: subtextBeforeTitle })), showTitle && (_jsxs("div", { className: "align-center flex flex-row gap-2", children: [_jsx(Heading, { as: "h1", children: customPageTitle !== null && customPageTitle !== void 0 ? customPageTitle : title }), pillMessage !== undefined && (_jsx("div", { className: "mt-2 lg:mt-0", children: _jsx(Pill, { id: "pageShell-pill", variant: "primary", message: pillMessage }) }))] })), subtextAfterTitle !== undefined && (_jsx("p", { className: "text-secondary dark:text-dark-secondary text-sm", children: subtextAfterTitle })), iconTextOptions && (_jsx("div", { className: "flex flex-row flex-wrap gap-x-4", children: iconTextOptions.iconTextGroup.map((item, index) => {
                                            return (_jsxs("div", { className: "text-secondary dark:text-dark-secondary flex flex-row gap-1 text-sm", children: [_jsx(IconV2, { type: "fa", icon: {
                                                            icon: item.icon,
                                                            size: "sm",
                                                        } }), item.text] }, index));
                                        }) }))] }))] }), _jsx("div", { className: "flex flex-col justify-center", children: (actionOptions !== undefined || useSplitbutton !== false) && (_jsx(_Fragment, { children: _jsxs("div", { className: "mt-4 flex w-full flex-col space-y-2 lg:mt-0 lg:flex-row lg:space-x-2 lg:space-y-0", children: [actionOptions === null || actionOptions === void 0 ? void 0 : actionOptions.actionButtons.map(function (btn, index) {
                                        var _a;
                                        const icon = btn.icon !== undefined ? { iconName: btn.icon } : {};
                                        const btnId = btn.id !== undefined ? { id: btn.id } : {};
                                        return (_jsx(Button, Object.assign({ type: (_a = btn.type) !== null && _a !== void 0 ? _a : "button", variant: btn.variant, disabled: btn.disabled, onClick: () => btn.handler(), form: btn.type === "submit" ? btn.form : undefined }, icon, btnId, { children: btn.label }), index));
                                    }), useSplitbutton !== false ? (_jsx(Splitbutton, Object.assign({}, useSplitbutton, { children: Array.isArray(splitButtonOptions) ? (splitButtonOptions.map((option, index) => {
                                            return _jsx(SplitbuttonOption, Object.assign({}, option), index);
                                        })) : (_jsx(SplitbuttonOption, Object.assign({}, splitButtonOptions, { label: splitButtonOptions
                                                ? splitButtonOptions.label
                                                : "Default Label" }))) }))) : (_jsx(_Fragment, {}))] }) })) })] })), props.children] }));
};
export default PageShell;
