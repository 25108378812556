var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState, } from "react";
import { Label } from "../label";
import { Input } from "../input";
import { CountryCode } from "../../country-code";
import { dialCodes } from "../../country-code/country-dial-codes";
import { AsYouType, formatNumber, getExampleNumber, } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
export const PhoneInput = (_a) => {
    var _b;
    var { id, testId, onCountryChange, onNumberChange, onNumberBlur, onNumberFocus } = _a, props = __rest(_a, ["id", "testId", "onCountryChange", "onNumberChange", "onNumberBlur", "onNumberFocus"]);
    let ref = useRef(null);
    const [mask, setMask] = useState("+1 (###) ###-####");
    const [country, setCountry] = useState((_b = props.country) !== null && _b !== void 0 ? _b : "US");
    const [inputVal, setInputVal] = useState();
    const [portalTarget, setPortalTarget] = useState();
    const handleCountryChange = (value) => {
        var _a;
        const phoneNumber = getExampleNumber(value.value, examples);
        const dialCode = (_a = dialCodes.find((code) => code.code === value.value)) === null || _a === void 0 ? void 0 : _a.dial_code;
        setMask(`${dialCode} ${phoneNumber.formatNational().replace(/[0-9]/gu, "#")}`);
        setCountry(value.value);
        if (onCountryChange) {
            onCountryChange(value);
        }
    };
    useEffect(() => {
        var _a, _b, _c;
        setPortalTarget(ref.current);
        if (props.number !== undefined) {
            const asYouType = new AsYouType();
            const parsedNumber = asYouType.input(props.number);
            if ((_a = asYouType.getNumber()) === null || _a === void 0 ? void 0 : _a.country) {
                setCountry((_b = asYouType.getNumber()) === null || _b === void 0 ? void 0 : _b.country);
                setMask(formatNumber(props.number, "INTERNATIONAL").replace(/[0-9]/gu, "#"));
            }
            setInputVal(parsedNumber);
        }
        else if (country !== undefined) {
            const phoneNumber = getExampleNumber(country, examples);
            const dialCode = (_c = dialCodes.find((code) => code.code === country)) === null || _c === void 0 ? void 0 : _c.dial_code;
            setMask(`${dialCode} ${phoneNumber.formatNational().replace(/[0-9]/gu, "#")}`);
        }
    }, [props.number, country]);
    return (_jsxs("div", { "data-eco-component": "phone-input", id: id, ref: ref, "data-test-id": testId, className: "flex-inline", children: [_jsx(Label, { name: props.name, label: props.label, required: props.required, optional: props.optional, hideLabel: props.hideLabel, disabled: props.disabled, labelPosition: props.labelPosition, children: _jsxs("div", { className: "flex flex-row items-end", children: [_jsx("div", { className: "mr-1 w-32 sm:w-48", children: _jsx(CountryCode, { id: `${id !== null && id !== void 0 ? id : "phone"}-country-code`, testId: `${id !== null && id !== void 0 ? id : "phone"}-country-code-test`, label: "Phone Country Code", menuPortalTarget: portalTarget, selected: country, disabled: props.disabled, invalid: props.invalid, hideLabel: true, name: `${props.name}-country-code`, compact: props.compact, onChange: (value) => handleCountryChange(value) }) }), _jsx(Input, { label: "Phone Number Input", name: `${props.name}-phone-input`, disabled: props.disabled, invalid: props.invalid, maskInput: true, hideLabel: true, maskFormat: mask, value: inputVal, mask: "_", onChange: onNumberChange, onBlur: onNumberBlur, onFocus: onNumberFocus })] }) }), props.helperText !== undefined && props.invalid !== true && (_jsx("p", { className: "text-secondary dark:text-dark-secondary mt-1 text-xs", children: props.helperText }))] }));
};
