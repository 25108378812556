var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState, useEffect, } from "react";
import Label from "../label/Label";
import { PatternFormat } from "react-number-format";
import { IconV2, convertIcon, needsConversion } from "../../icon_v2";
export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const InputComponent = (_a) => {
    var _b, _c, _d;
    var { id, testId, bgColor = "white", iconName, leadingText, onBlur, onFocus, onChange, onKeyDown, onClear, onMouseDown, onTouchEnd, labelPosition = "top" } = _a, props = __rest(_a, ["id", "testId", "bgColor", "iconName", "leadingText", "onBlur", "onFocus", "onChange", "onKeyDown", "onClear", "onMouseDown", "onTouchEnd", "labelPosition"]);
    let backgroundColor;
    if (props.invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else if (bgColor === "grey" || bgColor === "gray") {
        backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    let prefixIcon = iconName !== undefined && !Array.isArray(iconName)
        ? needsConversion(iconName)
            ? convertIcon(iconName)
            : iconName
        : iconName !== undefined && Array.isArray(iconName)
            ? iconName
            : undefined;
    let trailingIcon = props.trailingIconName !== undefined &&
        !Array.isArray(props.trailingIconName)
        ? needsConversion(props.trailingIconName)
            ? convertIcon(props.trailingIconName)
            : props.trailingIconName
        : props.trailingIconName !== undefined &&
            Array.isArray(props.trailingIconName)
            ? props.trailingIconName
            : undefined;
    const dimensions = {
        sm: "w-20",
        md: "w-28",
        lg: "w-36",
    };
    const heights = {
        sm: "h-8",
        md: "h-11",
    };
    const invalidClasses = props.invalid === true
        ? `border-error focus:ring-red-500 dark:border-dark-error  dark:focus:ring-red-400`
        : ``;
    const disabledClasses = props.disabled === true
        ? ` border-dark dark:border-dark-dark opacity-50 cursor-not-allowed`
        : ``;
    const readonlyClasses = props.readOnly === true
        ? ` form-field-readOnly form-field-readOnly-darkMode`
        : ``;
    const defaultClasses = props.invalid !== true && props.disabled !== true && props.readOnly !== true
        ? `border-interactive dark:border-dark-interactive`
        : ``;
    const inputNumberContainerClasses = classNames(props.type === "number" && props.size !== undefined
        ? `${dimensions[props.size]}`
        : `w-full`);
    const inputHeightContainerClass = props.height !== undefined ? heights[props.height] : heights.md;
    const inputHeightPadding = props.height === "sm" ? "py-1" : "";
    // conditionally includ the aria labeled by attr if input is not nested in the label
    const labelledby = labelPosition === "side" ? { "aria-labelledby": props.name } : {};
    // need to explicitly check for number type for Firefox and Safari, hence we check for all browsers
    const checkIfNumber = (e) => {
        const pattern = new RegExp("^[0-9]*$", "u");
        //keys for copy, paste, undo, redo
        const isCtrlKey = e.ctrlKey || e.metaKey;
        const allowedLetters = ["a", "c", "v", "z"];
        if (!pattern.test(e.key) &&
            e.key !== "Backspace" &&
            e.key !== "Enter" &&
            e.key !== "Tab" &&
            !(isCtrlKey && allowedLetters.includes(e.key)) &&
            e.key !== "." &&
            e.key !== "-") {
            e.preventDefault();
        }
    };
    const handleKeyDown = (e) => {
        if (props.type === "number") {
            checkIfNumber(e);
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    };
    /**
     * Handle On Clear Logic
     */
    const [showClearIcon, setShowClearIcon] = useState(false);
    const searchInputRef = useRef(null);
    const handleOnchange = (e) => {
        const searchValue = e.target.value;
        if (searchValue !== "") {
            if (!showClearIcon) {
                setShowClearIcon(true);
            }
        }
        else {
            if (showClearIcon) {
                setShowClearIcon(false);
            }
        }
        onChange(e);
    };
    const handleOnClear = (e) => {
        const searchInputItem = searchInputRef.current;
        searchInputItem.value = "";
        setShowClearIcon(false);
        if (onClear) {
            onClear(e);
        }
    };
    /**
     * END: Handle On Clear Logic
     */
    /**
     * Handle onWheel Event for "number" type
     */
    useEffect(() => {
        const searchInputItem = searchInputRef.current;
        if (searchInputItem) {
            searchInputItem.addEventListener("wheel", (e) => {
                e.preventDefault();
                searchInputItem.blur();
            });
        }
        return () => {
            if (searchInputItem) {
                searchInputItem.removeEventListener("wheel", (e) => {
                    e.preventDefault();
                    searchInputItem.blur();
                });
            }
        };
    }, [searchInputRef]);
    /**
     * END: Handle onWheel Event for "number" type
     */
    return (_jsxs("div", { className: `relative flex items-center border font-normal focus-within:ring-1 focus:ring-1 ${inputHeightContainerClass} ${defaultClasses}
        ${invalidClasses} ${inputNumberContainerClasses} ${readonlyClasses} ${disabledClasses} ${props.initialValueChanged === true
            ? "bg-amber-50"
            : backgroundColor} form-field-default form-field-default-darkMode`, "data-eco-component": "input-format", children: [iconName !== undefined && (_jsx("span", { className: `pointer-events-none flex items-center ${props.initialValueChanged === true
                    ? "form-field-change-indicator-leftIcon"
                    : "ml-3"}`, children: _jsx(IconV2, { type: "fa", icon: {
                        icon: prefixIcon,
                        fixedWidth: true,
                        size: "xl",
                    }, color: "base" }) })), leadingText !== undefined && (_jsx("div", { className: `pointer-events-none flex items-center ${props.initialValueChanged === true
                    ? "form-field-change-indicator-leadingText"
                    : "ml-3"}`, children: _jsx("span", { className: "text-gray-500", children: leadingText }) })), _jsx("input", Object.assign({ 
                // ! are for overriding the repeated tailwind form reset which shows up multiple times in portal apps. Once this issue is fixed, the focus! classes can be removed and the ! can be removed from rounded
                className: classNames(`text-primaryText dark:text-dark-primaryText bg-transparent ${invalidClasses} ${inputHeightPadding} placeholder:text-placeholder dark:placeholder:text-dark-placeholder relative block w-full border-none  focus:!border-0 focus:!shadow-none focus:!ring-0`, leadingText !== undefined ? "pl-1" : "", props.initialValueChanged === true
                    ? ""
                    : "!rounded"), id: `${id}-input`, "data-test-id": testId !== undefined ? `${testId}-input` : `${id}-input`, name: props.name, min: props.min, max: props.max, type: (_b = props.type) !== null && _b !== void 0 ? _b : "text", placeholder: props.placeholder, value: props.value, readOnly: props.readOnly, required: props.required, disabled: props.disabled, onBlur: onBlur, onFocus: onFocus, onChange: props.isClearable === true ? handleOnchange : onChange, onKeyDown: handleKeyDown, onMouseDown: onMouseDown, onTouchEnd: onTouchEnd, "aria-invalid": props.invalid, ref: searchInputRef }, labelledby)), props.trailingText !== undefined && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            _jsx("div", { className: classNames(`flex items-center`, !props.trailingIconOnClick ? "pointer-events-none" : "", props.initialValueChanged === true
                    ? "form-field-change-indicator-trailingText"
                    : "mr-3"), onClick: () => { var _a; return (_a = props.trailingIconOnClick) === null || _a === void 0 ? void 0 : _a.call(props); }, children: _jsx("span", { className: `text-gray-500`, children: props.trailingText }) })), props.isClearable !== undefined && showClearIcon && (_jsx("span", { className: "pointer-events-cursor mx-2 flex items-center", children: _jsxs("button", { type: "button", className: "ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center text-gray-400 hover:text-gray-500 focus:bg-transparent focus:text-white focus:outline-none", onClick: (e) => handleOnClear(e), children: [_jsx("span", { className: "sr-only", children: "Clear Search" }), _jsx("span", { className: "text-secondary text-base", children: _jsx(IconV2, { type: "fa", icon: {
                                    icon: ["far", "xmark"],
                                    fixedWidth: true,
                                    size: "lg",
                                } }) })] }) })), props.trailingIconName !== undefined && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            _jsx("span", { className: classNames("text-tertiary dark:text-dark-tertiary flex items-center", !props.trailingIconOnClick ? "pointer-events-none" : "", props.initialValueChanged === true
                    ? "form-field-change-indicator-trailingIconName"
                    : "pr-3"), onClick: () => { var _a; return (_a = props.trailingIconOnClick) === null || _a === void 0 ? void 0 : _a.call(props); }, children: _jsx(IconV2, { type: "fa", icon: {
                        icon: trailingIcon,
                        fixedWidth: true,
                        size: "lg",
                    } }) })), props.enableChangeIndicatorUI &&
                !((_c = props.readOnly) !== null && _c !== void 0 ? _c : false) &&
                !((_d = props.disabled) !== null && _d !== void 0 ? _d : false) && (_jsx("span", { className: "text-secondary pointer-events-none flex items-center pr-3", children: props.initialValueChanged ===
                    true ? (_jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "link-simple-slash"],
                        fixedWidth: true,
                        size: "lg",
                    } })) : (_jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "link-simple"],
                        fixedWidth: true,
                        size: "lg",
                    } })) }))] }));
};
export const Input = (props) => {
    var _a, _b, _c;
    return (_jsxs("div", { "data-eco-component": "input", id: props.id, "data-test-id": props.testId, children: [_jsx(Label, Object.assign({ required: props.required, optional: props.optional, htmlFor: `${props.id}-input`, hideLabel: props.hideLabel, disabled: props.disabled, labelPosition: props.labelPosition, tooltipText: props.tooltipText }, props, { children: ((_a = props.maskInput) !== null && _a !== void 0 ? _a : false) ? (_jsx(_Fragment, { children: _jsx(PatternFormat, Object.assign({ customInput: InputComponent, format: (_b = props.maskFormat) !== null && _b !== void 0 ? _b : "", mask: props.mask, allowEmptyFormatting: ((_c = props.showMask) !== null && _c !== void 0 ? _c : true) ? true : false }, props)) })) : (_jsx(InputComponent, Object.assign({}, props))) })), props.helperText !== undefined && props.invalid !== true && (_jsx("p", { className: "text-tertiary dark:text-dark-tertiary mt-1 text-xs", children: props.helperText }))] }));
};
export default Input;
